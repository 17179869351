import { useEffect } from 'react'

export default function Custom404() {
    const location =
        typeof window !== 'undefined' && window.location
            ? window.location
            : '';
    useEffect(() => {
        const domain = location.hostname.match(/([a-z0-9-]*?)\.[a-z]{2,}$/)[1];
        window.location.assign(`https://www.${domain}.com/404`)
    })
    return <h1>404 - Page Not Found</h1>;
}
